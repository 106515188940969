import {
  StateTree,
  PiniaPluginContext,
  SubscriptionCallback,
  SubscriptionCallbackMutationPatchObject
} from 'pinia'
import { Wallet } from '@injectivelabs/wallet-base'
import { defineNuxtPlugin } from '#imports'
import { getNetworkErrorFromStoreActionType } from '@/app/utils/network'
import { localStorage } from '@/app/Service'

const stateToPersist = {
  sharedWallet: {
    address: '',
    session: '',
    addresses: '',
    injectiveAddress: '',
    walletConnectStatus: '',
    wallet: Wallet.Metamask,
    addressConfirmation: ''
  }
} as Record<string, Record<string, any>>

const persistState = (
  mutation: SubscriptionCallbackMutationPatchObject<StateTree>,
  state: StateTree
) => {
  if (!stateToPersist[mutation.storeId]) {
    return
  }

  const keysToPersist = Object.keys(stateToPersist[mutation.storeId])

  if (!mutation.payload) {
    return
  }

  const shouldPersistState =
    keysToPersist.length > 0 &&
    Object.keys(mutation.payload || []).some((key) => {
      return keysToPersist.includes(key)
    })

  if (!shouldPersistState) {
    return
  }

  const updatedState = keysToPersist.reduce((stateObj, key) => {
    return {
      ...stateObj,
      [key]: mutation.payload[key] || state[key]
    }
  }, {})

  const existingState = (localStorage.get('state') || {}) as any

  localStorage.set('state', {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: {
      ...updatedState
    }
  })
}

const actionsThatCheckForNetworkError = [
  'dmm/fetchEpochs',
  'dmm/fetchCurrentEpochMarketSummary',
  'dmm/fetchTotalScores',
  'dmm/fetchRewardsDistribution',
  'dmm/fetchTotalScoresHistory',
  'dmm/fetchRewardsEligibility'
]

function piniaStoreSubscriber({ store }: PiniaPluginContext) {
  const localState = localStorage.get('state') as any
  const appStore = useAppStore()

  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] }
  }

  store.$subscribe(persistState as SubscriptionCallback<StateTree>)

  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      const type = `${$id}/${name}`

      if (actionsThatCheckForNetworkError.includes(type)) {
        const networkErrorTypes = appStore.networkErrorTypes
        const networkErrorType = getNetworkErrorFromStoreActionType(type)

        appStore.$patch({
          networkErrorTypes: networkErrorTypes.filter(
            (type) => type !== networkErrorType
          )
        })
      }
    })

    onError(() => {
      const type = `${$id}/${name}`

      if (actionsThatCheckForNetworkError.includes(type)) {
        const networkErrorTypes = appStore.networkErrorTypes
        const networkErrorType = getNetworkErrorFromStoreActionType(type)

        appStore.$patch({
          networkErrorTypes: [...networkErrorTypes, networkErrorType]
        })
      }
    })
  })
}

export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber)
  }
)
