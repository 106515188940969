import dmm from './en/dmm'
import wallet from './en/wallet'
import landing from './en/landing'
import insurance from './en/insurance'
import { NetworkErrorType } from '@/types'

export default {
  ...dmm,
  ...wallet,
  ...landing,
  ...insurance,

  pagination: {
    showRows: 'Show rows',
    showCountOutOfTotal: 'Showing {from} - {to} out of {totalCount}'
  },

  common: {
    max: 'Max',
    dmm: 'DMM',
    docs: 'Docs',
    stats: 'Stats',
    amount: 'Amount',
    search: 'Search',
    success: 'Success',
    explorer: 'Explorer',
    available: 'Available',
    tradeAndEarn: 'T&E Dashboard',
    rewardPrograms: 'Reward Programs',
    openLiquidityProgram: 'Open Liquidity Program'
  },

  // Maintenance:
  maintenanceHeader: 'Ongoing Upgrade',
  maintenanceSubHeader:
    'We are working on improving your experience. We will be back soon.',

  // Network Error
  networkError: {
    [NetworkErrorType.Epoch]: 'Network error: Unable to fetch epochs.',
    [NetworkErrorType.Markets]: 'Network error: Unable to fetch markets.',
    [NetworkErrorType.Scores]: 'Network error: Unable to fetch scores.',
    [NetworkErrorType.RewardsDistribution]:
      'Network error: Unable to fetch rewards.',
    [NetworkErrorType.Snapshots]: 'Network error: Unable to fetch snapshots.',
    [NetworkErrorType.Eligibility]:
      'Network error: Unable to fetch eligibility details.'
  },

  // no record
  noRecord: {
    [NetworkErrorType.Epoch]: 'No epochs found.',
    [NetworkErrorType.Scores]: 'No scores found.',
    [NetworkErrorType.Markets]: 'No markets found.',
    [NetworkErrorType.Snapshots]: 'No snapshots found.',
    [NetworkErrorType.RewardsDistribution]: 'No rewards found.',
    [NetworkErrorType.Eligibility]: 'No eligibility details found.',

    noResultsFound: 'No results for {search}'
  },

  toast: {
    copyAddress: 'Address copied'
  }
}
