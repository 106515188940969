
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as envuzzy1ssw26Meta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/env.vue?macro=true";
import { default as indexDqCyuIfWweMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/index.vue?macro=true";
import { default as indexR2xVvmlGdiMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund/index.vue?macro=true";
import { default as pending_45redemptions6wOTohZT9SMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund/pending-redemptions.vue?macro=true";
import { default as insurance_45fundBxlJfPzk6JMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund.vue?macro=true";
import { default as maintenancegOyvYQOLF7Meta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/maintenance.vue?macro=true";
import { default as eligibilityyZgmL33hyNMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/eligibility.vue?macro=true";
import { default as indexlaXkeH0lh8Meta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/index.vue?macro=true";
import { default as rewardsLNykyr3YHSMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/rewards.vue?macro=true";
import { default as scores57gXWoHwpEMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/scores.vue?macro=true";
import { default as snapshots4JlagbPSxBMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/snapshots.vue?macro=true";
import { default as liquidityLRmSQnMt6GMeta } from "/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envuzzy1ssw26Meta || {},
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexDqCyuIfWweMeta || {},
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/index.vue")
  },
  {
    name: insurance_45fundBxlJfPzk6JMeta?.name,
    path: "/insurance-fund",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund.vue"),
    children: [
  {
    name: "insurance-fund",
    path: "",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund/index.vue")
  },
  {
    name: "insurance-fund-pending-redemptions",
    path: "pending-redemptions",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/insurance-fund/pending-redemptions.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancegOyvYQOLF7Meta || {},
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/maintenance.vue")
  },
  {
    name: liquidityLRmSQnMt6GMeta?.name,
    path: "/program/liquidity",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity.vue"),
    children: [
  {
    name: "program-liquidity-eligibility",
    path: "eligibility",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/eligibility.vue")
  },
  {
    name: "program-liquidity",
    path: "",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/index.vue")
  },
  {
    name: "program-liquidity-rewards",
    path: "rewards",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/rewards.vue")
  },
  {
    name: "program-liquidity-scores",
    path: "scores",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/scores.vue")
  },
  {
    name: "program-liquidity-snapshots",
    path: "snapshots",
    component: () => import("/home/runner/work/injective-trading-ui/injective-trading-ui/pages/program/liquidity/snapshots.vue")
  }
]
  }
]